$myblue: #d3effa;

$success: #00adf3;

$card-border-color: $myblue;
$card-cap-bg: $myblue;
$card-cap-color: black;

@import "~bootstrap/scss/bootstrap";


body {
    background-color: white;
}

label {
    margin-bottom: 1px !important;
}

.form-group {
    margin-bottom: 9px !important;
}

.jumbotron {
    background-color: white;
}

.card-text {
    font-size: 0.9rem;
    font-style: italic;
    color: grey;
    text-align: justify;
}

.rmainfos ul {
    margin-bottom: 0px;
}

.rmainfos {
    font-size: 12px;
    max-height: 150px;
    border-color: $myblue !important;
    background-color: $myblue !important;
}

.flag {
    width: 20px;
    float: right;
}

.products {
    margin: 5px;
}

.actions a {
    float: right;
    display: inline-block;
}

a#sameaddress, a#addproduct {
    float: right;
    color: white;
}